<template>
  <div class="footer__social">
    <a
      class="footer__social__link"
      v-for="({ link, label }, socialIndex) in items.children"
      :key="`footer-social-${socialIndex}`"
      :href="(link && `https://${link}`) || '/'"
      target="_blank"
    >
      <nuxt-img
        class="footer__social__icon"
        :src="`/social/${label && label.toLowerCase()}.svg`"
        alt="social-icon"
      />
    </a>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
// import {} from '@storefront-ui/vue';

export default defineComponent({
  name: 'FooterSocial',
  components: {},
  props: {
    items: {
      type: Object,
      default: () => {},
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
.footer__social {
  display: flex;
  padding: 2.5625rem 1rem;
  &__link {
    padding: 0 1.25rem;
    @include flex-center;
    &:not(:last-child) {
      border-right: 0.0063rem solid #363636;
    }
    &:first-child {
      padding-left: 0;
    }
  }
}
@include from-desktop-min {
  .footer__social {
    padding: 0;
    height: fit-content;
    min-height: 2.5rem;
    align-items: center;
  }
}
</style>
