import FooterLogo from './FooterLogo.vue';
import FooterLink from './FooterLink.vue';
import FooterSocial from './FooterSocial.vue';
import FooterContact from './FooterContact.vue';
import FooterNewsletter from './FooterNewsletter.vue';
import FooterInfo from './FooterInfo.vue';
import FooterPayment from './FooterPayment.vue';
import FooterLegal from './FooterLegal.vue';
import FooterCredits from './FooterCredits.vue';

export const FooterComponents = {
  FooterLogo,
  FooterLink,
  FooterSocial,
  FooterContact,
  FooterNewsletter,
  FooterInfo,
  FooterPayment,
  FooterLegal,
  FooterCredits,
};
