<template>
  <div class="footer__credits">
    <span>
      Designed by
      <a href="https://www.yuppy.company/">Yuppy </a> &
      <a href="https://www.gemini-commerce.com/"> Gemini Commerce </a>
    </span>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'FooterCredits',
  setup() {},
});
</script>

<style lang="scss">
.footer__credits {
  display: flex;
  justify-content: center;
  height: 2.5rem;
  text-align: center;
  font-family: var(--font-family-albert-sans);
  color: var(--c-primary-lightest);
  font-size: var(--font-size-13);
  line-height: 1.4375rem;
  letter-spacing: 0.0081rem;
  padding: 0.5rem;
  a {
    text-decoration: underline;
    color: inherit;
  }
}
</style>
